import { random, times } from 'lodash';
import { sleep } from 'helpers/common';
import { ACTIVE, PAUSED } from 'consts';
import axios from 'axios';
import { startOfMonth, endOfToday } from 'date-fns';

const rnir = (number, margin, float) => {
  return random(number - margin, number + margin, float);
};

const rfa = (array) => {
  return array[random(0, array.length - 1)];
};

const rchart = () => {
  return {
    labels:
      random(0, 1) === 0
        ? ['12 AM', '4 AM', '8 AM', '12 PM', '4 PM', '8 PM', '11:59 PM']
        : [
            '01/25/21',
            '01/26/21',
            '01/27/21',
            '01/28/21',
            '01/29/21',
            '01/30/21',
            '01/31/21',
          ],
    volume: times(7, () => rnir(100, 50)),
    spent: times(7, () => rnir(1500, 600)),
  };
};

export async function fetchSellCampaignsOverviewMockData() {
  await sleep(500);

  return {
    creditBalance: rnir(1855, 500, true),
    spent: rnir(4222, 1000, true),
    bought: rnir(242, 50),

    bidSuccessRate: rnir(74, 20),
    averageCost: rnir(16.4, 5, true),

    paceSpent: rnir(4222, 1000, true),
    paceLeads: rnir(242, 50),

    chart: rchart(),
  };
}

const campaignNames = [
  'Add and Remodel - Beverly Hills Plain Shore',
  'Add and Remodel - Beverly Hills',
  'Painting 01 test',
  'Roofing - Danville ONLY',
  'Plumbing - Walnut Creek month july 21',
  'Melisandre',
  'Clifford',
  'Frances',
  'Roxie',
];

const campaignStatuses = [ACTIVE, PAUSED];

const categoriesNames = [
  'Plumbing',
  'Roofing',
  'Painting',
  'Additions and Remodels',
  'Cabinets and Countertops',
  'Melisandre',
  'Clifford',
  'Frances',
  'Roxie',
];

const subcategories = [
  '1500',
  'Ram',
  'Accord',
  'BRZ',
  'F150',
  'Altima',
  'Optima',
  'Fusion',
  'Wrangler',
  'Focus',
];

export async function fetchSellLeadsCampaignsMockData(length = 24) {
  await sleep(500);

  return {
    total: {
      leadsUploaded: 435,
      leadsBought: 436,
      leadsSold: 5800,
      avgPrice: 25,
    },
    rows: times(length, () => {
      return {
        id: random(100000, 900000),
        campaign: rfa(campaignNames),
        status: rfa(campaignStatuses),
        leadsUploaded: random(20, 100),
        leadsSold: random(100000, 900000),
        avgPrice: rnir(25, 10),
        successRate: random(0, 1, true),
        chart: rchart(),
      };
    }),
  };
}

export async function fetchSellLeadsCampaignMockData(length = 24) {
  await sleep(500);
  return {
    total: {
      leadsUploaded: 435,
      leadsSold: 5800,
      avgPrice: 25,
    },
    rows: times(length, () => {
      return {
        id: random(100000, 900000),
        status: rfa(campaignStatuses),
        leadType: 'Refinance',
        leadsUploaded: random(20, 100),
        leadsSold: random(100000, 900000),
        avgPrice: rnir(25, 10),
        successRate: random(0, 1, true),
        campaign: rfa(campaignNames),
      };
    }),
  };
}

export async function fetchBuyLeadsLeadsMockData(length = 24) {
  await sleep(500);

  const {
    data: { results: users },
  } = await axios.get(`https://randomuser.me/api/?results=${length}`);

  const date = new Date(rnir(1640007144589, 11095555550)).toISOString();

  return {
    rows: users.map((user) => {
      return {
        id: random(300000001, 399999999),
        transactionDate: date,
        email: user.email,
        name: `${user.name.first} ${user.name.last}`,
        phone: '9103307779',
        campaign: rfa(campaignNames),
        category: rfa(categoriesNames),
        subcategory: 'Additons - Garage',
        cost: rnir(25, 10),
        address: '268 High st Jefferson, OR 97352',
        time: date,
        price: random(10, 50, true),
      };
    }),
  };
}

export async function fetchBuyClicksCampaignLocationsMockData(length = 24) {
  await sleep(500);

  return {
    rows: times(length, () => {
      return {
        id: random(100000, 900000),
        campaign: rfa(campaignNames),
        geography: 'All States (National)',
        date: new Date(rnir(1640007144589, 11095555550)).toISOString(),
        subcategory: rfa(subcategories),
        status: rfa(campaignStatuses),
        budget: random(500, 2500),
        deliveryLocation:
          'https://cardealfinder.1800carshow.com?gid=0000-3029&make=[make]&model=[model]&zipcode=[zip]&contactdrillin=true&condition=used',
        spent: rnir(1875, 200),
        clicks: random(20, 100),
        bsr: rnir(25, 10),
        conversion: rnir(25, 10),
        conversionRate: rnir(25, 10),
        spentConversion: rnir(25, 10),
      };
    }),
  };
}

export async function fetchFinanceTransactionsMockData(
  timeframe_after,
  timeframe_before,
  limit,
  length = 24,
) {
  await sleep(500);
  const currentMonth = startOfMonth(new Date());
  const startDate = timeframe_after || startOfMonth(currentMonth).toISOString();
  const endDate = timeframe_before || endOfToday().toISOString();

  return {
    count: length,
    results: times(length, () => {
      return {
        created: new Date(
          random(new Date(startDate).getTime(), new Date(endDate).getTime()),
        ).toISOString(),
        transaction_type: rfa([
          'Pre-pay',
          'Account Credit',
          'Lorem ipsum dolor sit amet',
        ]),
        amount: random(100, 9000),
        balance: random(200, 9000),
        description: '',
      };
    }),
  };
}
