/* eslint-disable react-hooks/rules-of-hooks */
import { useQueryString } from 'hooks/hooks';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { selectMe, selectBuyer } from 'store/slices/accountSlice';
import { useGetHasBuyClicksCampaignsQuery } from 'store/slices/buyClicksCampaignApi';
import { useGetHasBuyLeadsCampaignsQuery } from 'store/slices/buyLeadsCampaignApi';

export const checkLoggedInAndVerified = () => {
  const me = useSelector(selectMe);
  const { urlAction } = useParams();

  if (urlAction === 'verification-code') {
    return;
  }

  if (!!me && me.emailVerified && me.phoneVerified) {
    return ['/'];
  }
};

export const checkLoggedIn = () => {
  const history = useHistory();

  const me = useSelector(selectMe);
  if (!me) {
    return ['/auth/login', true];
  }

  if (history.location.pathname === '/account/info/edit') return;

  if (!!me && !me.isImpersonated && (!me.emailVerified || !me.phoneVerified)) {
    return ['/auth/verification', true];
  }
};

export const checkHasNoBuyCampaigns = () => {
  const { data: hasClicksCampaigns } = useGetHasBuyClicksCampaignsQuery();
  const { data: hasLeadsCampaigns } = useGetHasBuyLeadsCampaignsQuery();
  let hasBuyCampaign = hasClicksCampaigns || hasLeadsCampaigns;

  const {
    query: { proceedToBilling },
  } = useQueryString();

  if (proceedToBilling) {
    hasBuyCampaign = false;
  }

  const redirect = checkLoggedIn();

  if (redirect) return redirect;

  if (hasBuyCampaign) {
    return ['/'];
  }
};

export const checkIsClickBuyer = () => {
  const buyer = useSelector(selectBuyer);

  const redirect = checkLoggedIn();

  if (redirect) return redirect;

  if (!buyer?.isClickBuyer) {
    return ['/'];
  }
};

export const checkIsLeadBuyer = () => {
  const buyer = useSelector(selectBuyer);

  const redirect = checkLoggedIn();

  if (redirect) return redirect;

  if (!buyer?.isLeadBuyer) {
    return ['/'];
  }
};
