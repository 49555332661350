import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import BlIcon from 'components/icons/BlIcon';

import s from './styled';

const Dialog = forwardRef(function Dialog(
  {
    children,
    className,
    onClose,
    onCloseIcon = <BlIcon icon="close" />,
    hideClose,
    ...rest
  },
  ref,
) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <MuiDialog
      ref={ref}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      className={clsx(className)}
      {...rest}
    >
      {onClose && !hideClose ? (
        <s.IconButton
          aria-label="Close"
          className={clsx(s.close, 'Dialog-iconButton', 'Dialog-close')}
          onClick={onClose}
        >
          {onCloseIcon}
        </s.IconButton>
      ) : null}
      {children}
    </MuiDialog>
  );
});

Dialog.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  onCloseIcon: PropTypes.node,
  hideClose: PropTypes.bool,
};

export default Dialog;
