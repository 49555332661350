import React, { forwardRef } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';

import Route from '../Route';

const withProtectedRoute = (useFobidden) => (WrappedComponent) => {
  const WithProtectedRouteComponent = forwardRef(
    function WithProtectedRouteComponent(props, ref) {
      const history = useHistory();
      const params = useParams();
      const location = useLocation();
      const [redirect, saveHref] =
        useFobidden?.({
          props,
          history,
          params,
          location,
        }) || [];

      if (!!redirect) {
        return (
          <Redirect
            to={{
              pathname: redirect,
              state: saveHref
                ? {
                    originalHref:
                      history.location.state?.originalHref ||
                      history.createHref(location),
                  }
                : undefined,
            }}
          />
        );
      }

      return <Route {...props} component={WrappedComponent} ref={ref} />;
    },
  );

  if (WrappedComponent.load) {
    WithProtectedRouteComponent.load = WrappedComponent.load;
  }
  if (WrappedComponent.preload) {
    WithProtectedRouteComponent.preload = WrappedComponent.preload;
  }
  WithProtectedRouteComponent.displayName = `withProtectedRoute(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithProtectedRouteComponent;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withProtectedRoute;
