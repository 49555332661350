import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { Route } from 'react-router-dom';

import { useRouterHelper } from 'hooks/useRouterHelper';
import { useMarketTypeLS } from 'hooks/useMarketTypeLS';
import { useBreakpoints } from 'helpers/styles';
import { getEnvVars } from 'helpers/common';
import { BUY } from 'consts';
import s from './styled';

const { REACT_APP_DISABLE_SELL } = getEnvVars();

const Header = memo(
  forwardRef(function Header(props, ref) {
    const { open: openProp } = props;

    const { isSmUp, isMdUp } = useBreakpoints();
    const routerHelper = useRouterHelper();
    const { marketType } = useMarketTypeLS();

    /**
     * Open state
     */
    const [open, setOpen] = useState(openProp);

    useEffect(() => {
      setOpen(openProp);
    }, [openProp]);

    const links = useMemo(() => {
      return getLinks({ ...routerHelper, marketType });
    }, [routerHelper, marketType]);

    return (
      <>
        <s.HeaderAppBar
          position="fixed"
          color="default"
          {...props}
          ref={ref}
          $open={open}
        >
          <Container className="Header-container">
            <s.NavBox>
              {links.map((item) => {
                return (
                  <Route path={item.url} key={item.url}>
                    {({ match: matchRouter, location: { pathname } }) => {
                      const match =
                        matchRouter || item?.alsoMatch?.includes(pathname);

                      return (
                        <s.NavLink
                          color="inherit"
                          to={item.url}
                          $active={!!match}
                        >
                          {!isSmUp && <s.NavLinkIcon icon={item.icon} />}
                          {item.label}
                        </s.NavLink>
                      );
                    }}
                  </Route>
                );
              })}
            </s.NavBox>

            {!REACT_APP_DISABLE_SELL && isMdUp && <s.MarketTypeToggleGroup />}
          </Container>
        </s.HeaderAppBar>
        <s.HeaderAppBarSpacing />
      </>
    );
  }),
);

Header.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
};

function getLinks(opts) {
  const { isBuy, isLeads, isSell, isClicks, isAccount, isFinance, marketType } =
    opts;

  const buyLeadsList = [
    {
      icon: 'list',
      label: 'Overview',
      url: '/buy/leads/overview',
    },
    {
      icon: 'campaign',
      label: 'Campaigns',
      url: '/buy/leads/campaigns',
      alsoMatch: ['/buy/leads/create-campaign', '/buy/leads/edit-campaign'],
    },
    {
      icon: 'leads',
      label: 'Leads',
      url: '/buy/leads/leads',
    },
    // {
    //   icon: 'graph',
    //   label: 'Analytics',
    //   url: '/buy/leads/analytics',
    // },
  ];

  const buyClicksList = [
    {
      icon: 'list',
      label: 'Overview',
      url: '/buy/clicks/overview',
    },
    {
      icon: 'campaign',
      label: 'Campaigns',
      url: '/buy/clicks/campaigns',
      alsoMatch: ['/buy/clicks/create-campaign', '/buy/clicks/edit-campaign'],
    },
    {
      icon: 'union',
      label: 'Clicks',
      url: '/buy/clicks/clicks',
    },
    // {
    //   icon: 'graph',
    //   label: 'Analytics',
    //   url: '/buy/clicks/analytics',
    // },
  ];

  const accountList = [
    {
      icon: 'account',
      label: 'Account',
      url: '/account/overview',
      alsoMatch: [
        '/account/info/edit',
        '/account/budget/edit',
        '/account/day-parting/edit',
      ],
    },
    {
      icon: 'billing',
      label: 'Billing',
      url: '/account/billing',
    },
  ];

  if (isBuy && isLeads) {
    return buyLeadsList;
  }

  if (isBuy && isClicks) {
    return buyClicksList;
  }

  if (isSell && isLeads && !REACT_APP_DISABLE_SELL) {
    return [
      {
        icon: 'list',
        label: 'Overview',
        url: '/sell/leads/overview',
      },
      {
        icon: 'campaign',
        label: 'Campaigns',
        url: '/sell/leads/campaigns',
        alsoMatch: ['/sell/leads/create-campaign', '/sell/leads/edit-campaign'],
      },
    ];
  }

  if (isAccount) {
    return accountList;
  }

  if (isFinance) {
    return [
      {
        icon: 'list',
        label: 'Overview',
        url: '/finances/overview',
      },
      {
        icon: 'billing',
        label: 'Statements',
        url: '/finances/statements',
      },
      {
        icon: 'dollar-circle',
        label: marketType === BUY ? 'Purchases' : 'Sales',
        url: '/finances/transactions',
      },
    ];
  }

  return accountList;
}

export default Header;
